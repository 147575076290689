import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import React, {useEffect, useRef, useState} from "react";
import {
    CheckboxChangeEvent, Input,
    InputChangeEvent, RadioGroup,
    RadioGroupChangeEvent, TextArea,
    TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {CalendarChangeEvent, DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {IAgFormFourthSectionInterface} from "../../../../../interfaces/forms/AgForm/ag-form-fourth-section.interface";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./AgForm.module.scss";
import {Error, Label} from "@progress/kendo-react-labels";
import {FormValidation} from "../InputValidator";
import SectionTitle from "../Shared/SectionTitle";
import { defaultRadioButtonData } from "../Shared/RadioButtonData";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IAgFormFourthSectionInterface) => void;
    formDataFields?:  IAgFormFourthSectionInterface,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function AgFormFourthSection(props: GeneralFormType): JSX.Element {
    const {setFormDataFields, formDataFields, formSettings, setFormIsValid} = props;

    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} name={"Section 3: Property Description and Information"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string
    ) {
        setFormDataFields({
            ...formDataFields,
            [field]: event.value
        });
    }

    function changeValidator() {
        if (formRef.current) {
            setIsValid(formRef.current.checkValidity());
            setFormIsValid(formRef.current.checkValidity());
        }
    }

    function onTouch() {
        if (!isFormTouched) {
            setIsFormTouched(true);
        }
    }

    const { validationMessage, inputValidator } = FormValidation(formRef);

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current]);

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.information}>Provide the descriptive information requested below for the property that is the subject of this application or attach last year’s tax statement, notice of appraised value or other correspondence identifying the property</div>
                        <div className={classes.item}>
                            <div className={classes.itemFields}>
                                <div className={`${classes.fieldsGroupW49}`}>
                                    <Label className={classes.Label}>
                                        Appraisal District Account Number (if known)
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    </Label>
                                    <div className="inputsGroup">
                                        <Input
                                            required={true}
                                            className={classes.kTextbox}
                                            name={"PropertyId"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.["PropertyId"]}
                                            onChange={(e)=> changeValueHandler(e, "PropertyId")}
                                        />
                                        {!inputValidator("PropertyId") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                </div>
                                <div className={`${classes.fieldsGroupW49}`}>
                                    <Label className={classes.Label}>
                                        Number of Acres (subject to this application)
                                    </Label>
                                    <div className="inputsGroup">
                                        <Input
                                            className={classes.kTextbox}
                                            name={"TotalAcreage"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.["TotalAcreage"]}
                                            onChange={(e)=> changeValueHandler(e, "TotalAcreage")}
                                        />
                                        {!inputValidator("TotalAcreage") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={`${classes.fieldsGroup}`}>
                                <Label className={classes.Label}>
                                    Legal description, abstract numbers, field numbers and/or plat numbers:
                                </Label>
                                <div className="inputsGroup">
                                    <TextArea
                                        name={"PropertyLegalDescription"}
                                        required={false}
                                        className={classes.kTextarea}
                                        autoSize={true}
                                        rows={2}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.PropertyLegalDescription}
                                        onChange={(e)=> changeValueHandler(e, "PropertyLegalDescription")}
                                    />
                                    {!inputValidator("PropertyLegalDescription") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="help-text help-info">
                            <div id="undefinedHelpText"><b>Select the appropriate box in response to each question below.</b></div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemFields}>
                                <div className={`${classes.flexGroup} ${classes.fieldsGroupW100}`}>
                                    <Label className={classes.Label}>
                                        Has the ownership of the property changed since Jan. 1 of last year or since the last application was submitted?
                                    </Label>
                                    <div className={classes.checkboxGroup}>
                                        <RadioGroup
                                            layout={"horizontal"}
                                            disabled={formSettings?.isReadOnly}
                                            data={defaultRadioButtonData}
                                            onChange={e => changeValueHandler(e, "HasPropertyOwnershipChanged")}
                                            value={formDataFields?.HasPropertyOwnershipChanged?.toString()}
                                        />
                                    </div>
                                    { formDataFields?.HasPropertyOwnershipChanged &&
                                        <div className="col-md-11">
                                            <div className="form-group form-field-type-help-text formio-component-undefinedHelpText ">
                                                <div className="help-text help-warning">
                                                    <div id="undefinedHelpText">The new
                                                        owner must complete all applicable questions and, if the land is
                                                        used to manage wildlife, sections 4 and 5 must be completed.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={`${classes.flexGroup} ${classes.fieldsGroupW100}`}>
                                    <Label className={classes.Label}>
                                        Has the former owner passed away?
                                    </Label>
                                    <div className={classes.checkboxGroup}>
                                        <RadioGroup
                                            layout={"horizontal"}
                                            disabled={formSettings?.isReadOnly}
                                            data={defaultRadioButtonData}
                                            onChange={e => changeValueHandler(e, "HasFormerOwnerPassedAway")}
                                            value={formDataFields?.HasFormerOwnerPassedAway?.toString()}
                                        />
                                    </div>
                                </div>
                                    { formDataFields?.HasFormerOwnerPassedAway?.toString() === 'true' &&
                                          <div className={`${classes.flexGroup} ${classes.fieldsGroupW100}`}>
                                          <Label className={classes.Label}>
                                          If yes, are you the surviving spouse of the former owner? 
                                          </Label>
                                          <div className={classes.checkboxGroup}>
                                              <RadioGroup
                                                  layout={"horizontal"}
                                                  disabled={formSettings?.isReadOnly}
                                                  data={defaultRadioButtonData}
                                                  onChange={e => changeValueHandler(e, "IsSurvivingSpouse")}
                                                  value={formDataFields?.IsSurvivingSpouse?.toString()}
                                              />
                                          </div>
                                      </div>
                                    }
                                
                            </div>
                            <div className={classes.itemFields}>
                                <div className={`${classes.flexGroup} ${classes.fieldsGroupW100}`}>
                                    <Label className={classes.Label}>
                                        Last year, was 1-d-1 appraisal allowed on this property by the chief appraiser of this appraisal district?
                                    </Label>
                                    <div className="inputsGroup">
                                        <RadioGroup
                                            layout={"horizontal"}
                                            name={"WasAllowed1D1Appraisal"}
                                            disabled={formSettings?.isReadOnly}
                                            data={defaultRadioButtonData}
                                            onChange={e => changeValueHandler(e, "WasAllowed1D1Appraisal")}
                                            value={formDataFields?.WasAllowed1D1Appraisal?.toString()}
                                        />
                                    </div>
                                    { formDataFields?.WasAllowed1D1Appraisal?.toString() === 'true' &&
                                        <div className="col-md-11">
                                            <div>
                                                <div className="help-text help-warning" >
                                                    <div id="undefinedHelpText">
                                                        Complete only those parts of sections 4 and 5 that have changed
                                                        since your earlier application or any information in sections 4
                                                        and 5 requested by the chief appraiser.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    { formDataFields?.WasAllowed1D1Appraisal?.toString() === 'false' &&
                                        <div className="col-md-11">
                                            <div className="form-group form-field-type-help-text formio-component-undefinedHelpText ">
                                                <div className="help-text help-warning">
                                                    <div id="undefinedHelpText">
                                                        All applicable questions must be completed and, if the land
                                                        is used to manage wildlife, sections 4 and 5 must be
                                                        completed.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={`${classes.flexGroup} ${classes.fieldsGroupW100}`}>
                                    <Label className={classes.Label}>
                                        Is this property located within the corporate limits of a city or town?
                                    </Label>
                                    <div className="inputsGroup">
                                        <RadioGroup
                                            layout={"horizontal"}
                                            name={"IsThisPropertyLocatedWithinTheCorporateLimitsOfaCityorTown"}
                                            disabled={formSettings?.isReadOnly}
                                            data={defaultRadioButtonData}
                                            onChange={e => changeValueHandler(e, "IsThisPropertyLocatedWithinTheCorporateLimitsOfaCityorTown")}
                                            value={formDataFields?.IsThisPropertyLocatedWithinTheCorporateLimitsOfaCityorTown?.toString()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
