export type IHomesteadExemptionSixthSectionInterface = {
    PrintedName?: string,
    Title?: string,
    Signature?: string,
    SignatureDate?: string,
    AdditionalOwnerSignature?:string,
    AdditionalOwnerDate?:string
}

export const HomesteadExemptionFormSixthSectionInitialState = (): IHomesteadExemptionSixthSectionInterface => ({
    PrintedName: undefined,
    Title: undefined,
    Signature: undefined,
    SignatureDate: undefined,
    AdditionalOwnerSignature: undefined,
    AdditionalOwnerDate: undefined,
})