export type IAgFormFourthSectionInterface = {
    PropertyId?: string,
    TotalAcreage?: string,
    PropertyLegalDescription?: string,
    HasPropertyOwnershipChanged?: boolean,
    HasFormerOwnerPassedAway?:boolean,
    IsSurvivingSpouse?:boolean,
    WasAllowed1D1Appraisal?: boolean,
    IsThisPropertyLocatedWithinTheCorporateLimitsOfaCityorTown?: boolean
}

export const AgFormFourthSectionInitialState = (): IAgFormFourthSectionInterface => ({
    PropertyId: undefined,
    TotalAcreage: undefined,
    PropertyLegalDescription: undefined,
    HasPropertyOwnershipChanged: undefined,
    IsSurvivingSpouse:undefined,
    HasFormerOwnerPassedAway:undefined,
    WasAllowed1D1Appraisal: undefined,
    IsThisPropertyLocatedWithinTheCorporateLimitsOfaCityorTown: undefined
})
