import { useEffect, useRef, useState } from "react";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import shared from "../PDF-Shared-style.module.scss";
import classes from "./HomesteadExemption-Form-PDF-style.module.scss";
import {
  IHomesteadExemptionFourthSectionInterface
} from "../../../interfaces/forms/HomesteadExemptionForm/homestead-exemption-fourth-section.interface";
import {
  IHomesteadExemptionFirstSectionInterface
} from "../../../interfaces/forms/HomesteadExemptionForm/homestead-exemption-first-section.interface";
import {
  IHomesteadExemptionSecondSectionInterface
} from "../../../interfaces/forms/HomesteadExemptionForm/homestead-exemption-second-section.interface";
import {
  IHomesteadExemptionThirdSectionInterface
} from "../../../interfaces/forms/HomesteadExemptionForm/homestead-exemption-third-section.interface";
import {
  IHomesteadExemptionFifthSectionInterface
} from "../../../interfaces/forms/HomesteadExemptionForm/homestead-exemption-fifth-section.interface";
import {
  IHomesteadExemptionSixthSectionInterface
} from "../../../interfaces/forms/HomesteadExemptionForm/homestead-exemption-sixth-section.interface";
import { settingsService } from "src/services/settings.service";
import { GetCheckbox, GetFixedWidthCheckbox, GetYesNoCheckboxGroup } from "../PDFCheckbox";
import { GetHeader } from "../PDFHeader";
import { GetFooter } from "../PDFFooter";
import { toPercent } from "src/utils/numbers";

interface IFormFields {
  formDataFieldsFirstSection?: IHomesteadExemptionFirstSectionInterface;
  formDataFieldsSecondSection?: IHomesteadExemptionSecondSectionInterface;
  formDataFieldsThirdSection?: IHomesteadExemptionThirdSectionInterface;
  formDataFieldsFourthSection?: IHomesteadExemptionFourthSectionInterface;
  formDataFieldsFifthSection?: IHomesteadExemptionFifthSectionInterface;
  formDataFieldsSixthSection?: IHomesteadExemptionSixthSectionInterface;
  getPdfBase64Url?: (url: string) => void;
}

export default function HomesteadExemptionFormPDFView(
  props: IFormFields
): JSX.Element {
  const {
    formDataFieldsFirstSection,
    formDataFieldsSecondSection,
    formDataFieldsThirdSection,
    formDataFieldsFourthSection,
    formDataFieldsFifthSection,
    formDataFieldsSixthSection,
    getPdfBase64Url,
  } = props;

  const MarriedCouplePropertyOwnerType = "MarriedCouple";
  const settings = settingsService().settings;

  const templateRef = useRef(null);
  const [templateBase64, setTemplateBase64] = useState("");

  useEffect(() => {
    drawDOM(templateRef.current as any, { paperSize: "A4" })
      .then((group) => {
        if (templateRef.current) {
          const myReference = templateRef.current as any;
          myReference.style.display = "none";
        }
        return exportPDF(group);
      })
      .then((dataUri) => {
        setTemplateBase64(dataUri);
        if (getPdfBase64Url) {
          getPdfBase64Url(dataUri);
        }
      });
  }, []);

  return (
    <>
      {templateRef && templateBase64 && (
        <iframe
          style={{ width: "100%", height: "100%" }}
          src={templateBase64}
        />
      )}
      <div ref={templateRef} className={`${shared.FormContainer} ${classes.FormContainer} ${shared.Form}`}>
        <div className={shared.PrintTogether}>
          <div className={`${shared.Row} ${shared.AlignCenter}`}>
            <div className={`${shared.Column9} ${shared.FormTitle}`}>
              Residence Homestead Exemption Application
            </div>
            <div className={`${shared.Width15} ${shared.FormVersion} ${shared.Font10}`}>Form 50-114</div>
          </div>

          <div className={shared.Row}>
            <div className={shared.Column7}>
              <div className={shared.Value}>{settings?.clientDisplayName}</div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                Appraisal District&apos;s Name
              </div>
            </div>

            <div className={shared.Column5}>
              <div className={shared.Value}>
                {formDataFieldsFourthSection?.PropertyId}
              </div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                Appraisal District Account Number <i>(if known)</i>
              </div>
            </div>
          </div>

          <div className={shared.Row}>
            <div className={`${shared.ChildRow} ${shared.Column5}`}>
            <div className={`${shared.Column8} ${shared.TextSmall}`}>
              Are you filing a late application?
            </div>
            <div className={`${shared.Column4} ${shared.TextRight}`}>
              {GetYesNoCheckboxGroup(formDataFieldsFirstSection?.IsLateApplication)}
            </div>
            </div>
            <div className={shared.Column7}>
              <div className={shared.Value}>
                {formDataFieldsFirstSection?.TaxYears?.join(", ")}
              </div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                Tax Year(s) for Application
              </div>
            </div>
          </div>

          <div className={shared.Row}>
            <div className={shared.Column12}>
              <hr className={shared.Hr}/>
            </div>
          </div>

          <div className={shared.Row}>
            <div className={shared.Column12}>
              <p>
                <b>GENERAL INFORMATION: </b>
                Property owners applying for a residence homestead exemption file
                this form and supporting documentation with the appraisal district
                in each county in which the property is located (Tax Code Sections
                11.13, 11.131, 11.132, 11.133, 11.134 and 11.432).
                <b> Do <u>not</u> file this form with the Texas Comptroller of Public Accounts.</b>
              </p>
            </div>
          </div>

          <div className={shared.Step}>
            SECTION 1: Exemption(s) Requested (Select all that apply.)
          </div>
          <div className={shared.Row}>
            <div className={shared.Column9}>
              Do you live in the property for which you are seeking this
              residence homestead exemption?
            </div>
            <div className={`${shared.Column2} ${shared.TextRight}`}>
              {GetYesNoCheckboxGroup(formDataFieldsSecondSection?.IsOwnerOfProperty)}
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              {GetCheckbox(formDataFieldsSecondSection?.IsGeneralHomesteadExemption,
                "General Residence Homestead Exemption", true)}
              &nbsp;&nbsp;
              {GetCheckbox(formDataFieldsSecondSection?.IsDisabledPersonExemption,
                "Disabled Person", true)}
              &nbsp;&nbsp;
              {GetCheckbox(formDataFieldsSecondSection?.IsAge65OrOlderExemption,
                "Person Age 65 or Older (or Surviving Spouse)", true)}
              &nbsp;&nbsp;
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              {GetCheckbox(formDataFieldsSecondSection?.IsDisabledVetExemption,
                "100 Percent Disabled Veteran (or Surviving Spouse)", true)}
              &nbsp;&nbsp;
            </div>
          </div>
          <div className={shared.Row}>
            <div className={`${shared.Column9} ${shared.MarginLeft20}`}>
              Is the disability a permanent total disability as determined by
              the U.S. Department of Veterans Affairs under 38 C.F.R. Section
              4.15?
            </div>
            <div className={`${shared.Column2} ${shared.TextRight}`}>
              {GetYesNoCheckboxGroup(formDataFieldsSecondSection?.IsPermanentTotalDisability)}
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              {GetCheckbox(formDataFieldsSecondSection?.IsSurvingSpouseOfKiaExemption,
                "Surviving Spouse of an Armed Services Member Killed or Fatally Injured in the Line of Duty", true)}
              &nbsp;&nbsp;
              {GetCheckbox(formDataFieldsSecondSection?.IsSurvingSpouseOfFirstResponder,
                "Surviving Spouse of a First Responder Killed in the Line of Duty", true)}
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column6}>
              {GetCheckbox(formDataFieldsSecondSection?.IsDonatedResidenceExemption,
                "Donated Residence of Partially Disabled Veteran (or Surviving Spouse)", true)}
              &nbsp;&nbsp;
            </div>
            <div className={shared.Column3}>
              <div className={shared.Value}>
                {formDataFieldsSecondSection?.DonatedResidenceExemptionPercentDisabilityRating}
              </div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                Percent Disability Rating
              </div>
            </div>
            <div className={shared.Column3}></div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column2}>
              <label>Surviving Spouse:</label>
            </div>
            <div className={shared.Column7}>
              <div className={shared.Value}>
                {formDataFieldsSecondSection?.SurvivingSpouseName}
              </div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>Name of Deceased Spouse</div>
            </div>
            <div className={shared.Column3}>
              <div className={shared.Value}>
                {formDataFieldsSecondSection?.SurvivingSpouseDateOfDeath}
              </div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>Date of Death</div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column9}>
              <label>Cooperative Housing:</label> Do you have an exclusive right to
              occupy this property because you own stock in a cooperative
              housing corporation?
            </div>
            <div className={`${shared.Column2} ${shared.TextRight}`}>
              {GetYesNoCheckboxGroup(formDataFieldsFirstSection?.PropertyIsInStockOfCoop)}
            </div>
          </div>
          <div className={shared.Row}>
            <div className={`${shared.Column4} ${shared.MarginLeft20}`}>
              If yes, state name of cooperative housing corporation:
            </div>
            <div className={shared.Column5}>
              <div className={shared.Value}>
                {formDataFieldsFirstSection?.CooperativeHousingCorporation}
              </div>
            </div>
            <div className={shared.Column2}/>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column9}>
              Were you receiving a homestead exemption on your previous residence?
            </div>
            <div className={`${shared.Column2} ${shared.TextRight}`}>
              {GetYesNoCheckboxGroup(formDataFieldsFirstSection?.WasReceivingExemptionOnPreviousResidence)}
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column9}>
              Are you transferring an exemption from a previous residence?
            </div>
            <div className={`${shared.Column2} ${shared.TextRight}`}>
              {GetYesNoCheckboxGroup(formDataFieldsFirstSection?.IsTransferringPreviousResidenceHomestead)}
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column9}>
              Are you transferring a tax limitation?
            </div>
            <div className={`${shared.Column2} ${shared.TextRight}`}>
              {GetYesNoCheckboxGroup(formDataFieldsFirstSection?.IsTransferringATaxLimitation)}
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column7}>
              <div className={shared.Value}>
                {formDataFieldsFirstSection?.PreviousResidenceAddressLine1} &nbsp;
                {formDataFieldsFirstSection?.PreviousResidenceAddressLine2 && (
                  <span>
                    {formDataFieldsFirstSection?.PreviousResidenceAddressLine2}
                  </span>
                )}
                {formDataFieldsFirstSection?.PreviousResidenceAddressCity} &nbsp;
                {formDataFieldsFirstSection?.PreviousResidenceAddressState}
                {formDataFieldsFirstSection?.PreviousResidenceAddressZip && ", "}
                {formDataFieldsFirstSection?.PreviousResidenceAddressZip} &nbsp;
              </div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                Previous Residence Address, City, State, Zip Code
              </div>
            </div>

            <div className={shared.Column5}>
              <div className={shared.Value}>
                {formDataFieldsFirstSection?.PreviousResidenceCounty}
              </div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                Previous County
              </div>
            </div>
          </div>

          <div className={shared.Step}>
            SECTION 2: Property Owner/Applicant (Provide information for
            additional property owners in Section 5.)
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <div>
                <label>Select One: &nbsp;&nbsp;&nbsp;</label>
                {GetCheckbox(formDataFieldsThirdSection?.PropertyOwnerType === "SingleAdult", "Single Adult")}
                &nbsp;&nbsp;
                {GetCheckbox(formDataFieldsThirdSection?.PropertyOwnerType === "MarriedCouple", "Married Couple")}
                &nbsp;&nbsp;
                {GetCheckbox(formDataFieldsThirdSection?.PropertyOwnerType === "Other",
                  "Other (e.g., individual who owns the property with others)")}
              </div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={`${shared.ChildRow} ${shared.Column9}`}>
              <div className={shared.Column7}>
                <div className={shared.Value}>
                  {formDataFieldsThirdSection?.PropertyOwnerName}
                </div>
                <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                  Name of Property Owner 1
                </div>
              </div>
              <div className={shared.Column5}>
                <div className={shared.Value}>
                  {
                    formDataFieldsThirdSection?.PropertyOwnerBirthDate ?
                        new Date(formDataFieldsThirdSection?.PropertyOwnerBirthDate).toDateString() :
                        formDataFieldsThirdSection?.PropertyOwnerBirthDate}
                </div>
                <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                  Birth Date* (mm/dd/yyyy)
                </div>
              </div>
            </div>
            <div className={shared.Column3}>
              <div className={shared.Value}>
                {formDataFieldsThirdSection?.PropertyOwnerIdNumber}
              </div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                Driver’s License, Personal ID Certificate or Social Security
                Number**
              </div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={`${shared.ChildRow} ${shared.Column9}`}>
              <div className={shared.Column5}>
                <div className={shared.Value}>
                  {formDataFieldsThirdSection?.PropertyOwnerPhoneNumber}
                </div>
                <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                  Primary Phone Number <i>(area code and number)</i>
                </div>
              </div>
              <div className={shared.Column7}>
                <div className={shared.Value}>
                  {formDataFieldsThirdSection?.PropertyOwnerEmailAddress}
                </div>
                <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>Email Address***</div>
              </div>
            </div>
            <div className={shared.Column3}>
              <div className={shared.Value}>
                {formDataFieldsThirdSection?.PropertyOwnerOwnershipInterest != undefined
                  ? toPercent(formDataFieldsThirdSection?.PropertyOwnerOwnershipInterest)
                  : undefined
                }
              </div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                Percent Ownership Interest
              </div>
            </div>
          </div>
          {(formDataFieldsThirdSection?.PropertyOwnerType === MarriedCouplePropertyOwnerType ||
                    !formDataFieldsThirdSection?.PropertyOwners?.length ||
                    formDataFieldsThirdSection?.PropertyOwners?.length === 0) &&
          <>
            <div className={shared.Row}>
              <div className={`${shared.ChildRow} ${shared.Column9}`}>
                <div className={shared.Column7}>
                  <div className={shared.Value}>
                    {formDataFieldsThirdSection?.PropertyOwnerSpouseName}
                  </div>
                  <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                    Name of Property Owner 2
                  </div>
                </div>
                <div className={shared.Column5}>
                  <div className={shared.Value}>
                    {
                      formDataFieldsThirdSection?.PropertyOwnerSpouseBirthDate ?
                          new Date(formDataFieldsThirdSection?.PropertyOwnerSpouseBirthDate).toDateString() :
                          formDataFieldsThirdSection?.PropertyOwnerSpouseBirthDate}
                  </div>
                  <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                    Birth Date* (mm/dd/yyyy)
                  </div>
                </div>
              </div>
              <div className={shared.Column3}>
                <div className={shared.Value}>
                  {formDataFieldsThirdSection?.PropertyOwnerSpouseIdNumber}
                </div>
                <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                  Driver’s License, Personal ID Certificate or Social Security
                  Number**
                </div>
              </div>
            </div>
            <div className={shared.Row}>
              <div className={`${shared.ChildRow} ${shared.Column9}`}>
                <div className={shared.Column5}>
                  <div className={shared.Value}>
                    {formDataFieldsThirdSection?.PropertyOwnerSpousePhoneNumber}
                  </div>
                  <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                    Primary Phone Number <i>(area code and number)</i>
                  </div>
                </div>
                <div className={shared.Column7}>
                  <div className={shared.Value}>
                    {formDataFieldsThirdSection?.PropertyOwnerSpouseEmailAddress}
                  </div>
                  <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>Email Address***</div>
                </div>
              </div>
              <div className={shared.Column3}>
                <div className={shared.Value}>
                  {formDataFieldsThirdSection?.PropertyOwnerSpouseOwnershipInterest != undefined
                    ? toPercent(formDataFieldsThirdSection?.PropertyOwnerSpouseOwnershipInterest)
                    : undefined
                  }
                </div>
                <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                  Percent Ownership Interest
                </div>
              </div>
            </div>
          </>
          }
          {formDataFieldsThirdSection?.PropertyOwners?.map((item, index)=> { return <>
              <div className={shared.Row}>
                <div className={`${shared.ChildRow} ${shared.Column9}`}>
                  <div className={shared.Column7}>
                    <div className={shared.Value}>
                      {item?.OwnerName}
                    </div>
                    <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                      Name of Property Owner {formDataFieldsThirdSection?.PropertyOwnerType === MarriedCouplePropertyOwnerType
                      ? index + 3 : index + 2}
                    </div>
                  </div>
                  <div className={shared.Column5}>
                    <div className={shared.Value}>
                      { item?.BirthDate ? new Date(item?.BirthDate).toDateString() : item?.BirthDate}
                    </div>
                    <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                      Birth Date* (mm/dd/yyyy)
                    </div>
                  </div>
                </div>
                <div className={shared.Column3}>
                  <div className={shared.Value}>
                    {item?.PersonalIdCertificate}
                  </div>
                  <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                    Driver’s License, Personal ID Certificate or Social Security
                    Number**
                  </div>
                </div>
              </div>
              <div className={shared.Row}>
                <div className={`${shared.ChildRow} ${shared.Column9}`}>
                  <div className={shared.Column5}>
                    <div className={shared.Value}>
                      {item?.PhoneNumber}
                    </div>
                    <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                      Primary Phone Number <i>(area code and number)</i>
                    </div>
                  </div>
                  <div className={shared.Column7}>
                    <div className={shared.Value}>
                      {item.EmailAddress}
                    </div>
                    <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>Email Address***</div>
                  </div>
                </div>
                <div className={shared.Column3}>
                  <div className={shared.Value}>
                    {item.PercentOwnership != undefined
                      ? toPercent(item.PercentOwnership)
                      : undefined
                    }
                  </div>
                  <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                    Percent Ownership Interest
                  </div>
                </div>
              </div>
            </>})}
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <div className={shared.Value}>
                {formDataFieldsThirdSection?.ApplicantAddressLine1}{" "} &nbsp;
                {formDataFieldsThirdSection?.ApplicantAddressLine2 && (
                  <span>
                    { formDataFieldsThirdSection?.ApplicantAddressLine2 }{" "} &nbsp;
                  </span>
                )}
                { formDataFieldsThirdSection?.ApplicantAddressCity }{" "} &nbsp;
                { formDataFieldsThirdSection?.ApplicantAddressState }
                { formDataFieldsThirdSection?.ApplicantAddressState && ", " }
                { formDataFieldsThirdSection?.ApplicantAddressZip }
              </div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                Applicant mailing address <i>(if different from the physical address)</i>
              </div>
            </div>
          </div>

          <div className={shared.Step}>SECTION 3: Property Information</div>
          <div className={shared.Row}>
            <div className={shared.Column4}>
              <div className={shared.Value}>
                { formDataFieldsFourthSection?.DatePurchasedProperty ?
                  new Date(formDataFieldsFourthSection?.DatePurchasedProperty).toDateString() :
                  formDataFieldsFourthSection?.DatePurchasedProperty }
              </div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                Date you acquired this property
              </div>
            </div>
            <div className={shared.Column8}>
              <div className={shared.Value}>
                { formDataFieldsFourthSection?.DateFirstOccupied ?
                  new Date(formDataFieldsFourthSection?.DateFirstOccupied).toDateString() :
                  formDataFieldsFourthSection?.DateFirstOccupied }
              </div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                Date you began occupying this property as your principal
                residence
              </div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <div className={shared.Value}>
                {formDataFieldsFourthSection?.PropertyAddressLine1}{" "} &nbsp;
                {formDataFieldsFourthSection?.PropertyAddressLine2 && (
                  <span>
                    { formDataFieldsFourthSection?.PropertyAddressLine2 }{" "} &nbsp;
                  </span>
                )}
                { formDataFieldsFourthSection?.PropertyAddressCity }{" "} &nbsp;
                { formDataFieldsFourthSection?.PropertyAddressState }
                { formDataFieldsFourthSection?.PropertyAddressState && ", " }
                { formDataFieldsFourthSection?.PropertyAddressZip }
              </div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                Physical Address <i>(i.e. street address, not P.O. Box)</i>, City, County, ZIP Code
              </div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <div className={`${shared.Value} ${shared.TextWrap}`}>
                {formDataFieldsFourthSection?.PropertyLegalDescription}
              </div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                Legal Description <i>(if known)</i>
              </div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={`${shared.Column12} ${shared.TextSmall}`}>
              Is the applicant identified on deed or other recorded instrument?
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column1}>
              {GetCheckbox(formDataFieldsFourthSection?.IsApplicantOnDeed == true, "Yes")}
            </div>
            <div className={shared.Column6}>
              <div className={shared.Value}>
                {formDataFieldsFourthSection?.CourtRecordorFilingNumber}
              </div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                Court record/filing number on recorded deed or other recorded
                instrument, if available
              </div>
            </div>
            <div className={shared.Column5}/>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column1}>
              {GetCheckbox(formDataFieldsFourthSection?.IsApplicantOnDeed == false, "No")}
            </div>
            <div className={`${shared.Column6} ${shared.TextSmall}`}>
              If no, required documentation must be provided. (see important information)
            </div>
            <div className={shared.Column5}>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column8}>
              Is the property for which this application is submitted an heir
              property (see Important Information)?
            </div>
            <div className={shared.Column2}>
              {GetYesNoCheckboxGroup(formDataFieldsFourthSection?.IsAnHeirProperty)}
            </div>
            <div className={shared.Column2}>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column8}>
              Do other heir property owners occupy the property?
            </div>
            <div className={shared.Column3}>
              {GetYesNoCheckboxGroup(formDataFieldsFourthSection?.DoOtherHeirPropertyOwnersOccupy, "Yes (affidavits required)")}
            </div>
            <div className={shared.Column1}>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column4}>
              <div className={shared.Value}>
                {formDataFieldsFourthSection?.MobileHomeMake}
              </div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>Manufactured Home Make</div>
            </div>
            <div className={shared.Column4}>
              <div className={shared.Value}>
                {formDataFieldsFourthSection?.MobileHomeModel}
              </div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>Model</div>
            </div>
            <div className={shared.Column4}>
              <div className={shared.Value}>
                {formDataFieldsFourthSection?.MobileHomeIdNumber}
              </div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>ID Number</div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column9}>
              Is any portion of the property for which you are claiming a
              residence homestead exemption income producing?
            </div>
            <div className={`${shared.Column2} ${shared.TextRight}`}>
              {GetYesNoCheckboxGroup(formDataFieldsFourthSection?.IsIncomeProducing)}
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <div>
                <span className={shared.MarginLeft20}>
                  If yes, indicate the percentage of the property that is income producing: &ensp;
                </span>
                <div className={`${shared.Value} ${shared.Width10} ${shared.InlineBlock}`}>
                  {formDataFieldsFourthSection?.PercentageOfPropertyThatIsIncomeProducing}
                </div>
                <span>
                  &nbsp;percent
                </span>
              </div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <span>
                Number of acres (or fraction of an acre, not to exceed 20 acres)
                you own and occupy as your principal residence: &ensp;
              </span>
              <div className={`${shared.Value} ${shared.Width30} ${shared.InlineBlock}`}>
                {formDataFieldsFourthSection?.PropertyNumberOfAcres}
              </div>
              <span>
                &nbsp;acres
              </span>
            </div>
          </div>
          <div className={`${shared.PageFooter} ${classes.PageFooter}`}>
            <div className={shared.Row}>
              <div className={shared.Column12}>
                <hr className={shared.Hr}/>
              </div>
            </div>
            <div className={shared.Row}>
              <div className={`${shared.Column7} ${shared.TextLeft} ${shared.Footer}`}>
                Form developed by: Texas Comptroller of Public Accounts, Property
                Tax Assistance Division
              </div>
              <div className={`${shared.Column5} ${shared.TextRight} ${shared.Footer}`}>
                For additional copies, visit:{" "}
                <b>comptroller.texas.gov/taxes/property-tax</b>
              </div>
            </div>
            <div className={shared.Row}>
              <div className={`${shared.Column12} ${shared.TextRight}`}>
              50-114 • Rev. 11-23/37
              </div>
            </div>
          </div>
        </div>
        <div className={shared.PageBreak}></div>

        <div className={shared.PrintTogether}>
          {GetHeader("Residence Homestead Exemption Application", "Form 50-114")}
          <div className={shared.Step}>
            SECTION 4: Waiver of Required Documentation
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              Indicate if you are exempt from the requirement to provide a copy
              of your driver’s license or state-issued personal identification
              certificate.
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              {GetCheckbox(formDataFieldsThirdSection?.IsResidentOfAFacility,
                "I am a resident of a facility that provides services related to health, infirmity or aging.")}
            </div>
          </div>
          <div className={shared.Row}>
            <div className={`${shared.Column12} ${shared.MarginLeft20}`}>
              <div className={shared.Value}>
                {formDataFieldsThirdSection?.FacilityAddressLine1}{" "} &nbsp;
                {formDataFieldsThirdSection?.FacilityAddressLine2 && (
                  <span>
                    { formDataFieldsThirdSection?.FacilityAddressLine2 }{" "} &nbsp;
                  </span>
                )}
                { formDataFieldsThirdSection?.FacilityAddressCity }{" "} &nbsp;
                { formDataFieldsThirdSection?.FacilityAddressState }
                { formDataFieldsThirdSection?.FacilityAddressState && ", " }
                { formDataFieldsThirdSection?.FacilityAddressZip }
              </div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
              Facility Name and Address
              </div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              {GetCheckbox(formDataFieldsThirdSection?.IsCertifiedForAddressConfidentialityProgram,
                "I am certified for participation in the address confidentiality program administered by the Office of the Texas Attorney General under Code of Criminal Procedure Chapter 58, Subchapter B.")}
            </div>
          </div>
          <br/>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              Indicate if you request that the chief appraiser waive the
              requirement that the property address for exemption corresponds
              to your driver’s license or state-issued personal identification
              certificate address:
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              {GetCheckbox(formDataFieldsThirdSection?.IsActiveDutyMilitary,
                "I am an active duty U.S. armed services member or the spouse of an active duty member.")}
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              {GetCheckbox(formDataFieldsThirdSection?.IsSection521DriversLicenseHolder,
                "I hold a driver’s license issued under Transportation Code Section 521.121(c) or 521.1211. Attached is a copy of the application for that license.")}
            </div>
          </div>

          <div className={shared.Step}>
            SECTION 5: Provide Additional Information Here (If any)
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <div className={shared.TextArea}>
                {formDataFieldsFifthSection?.AdditionalInformation}
              </div>
            </div>
          </div>
          <br/>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              If you own other residential property in Texas, please list the
              county(ies) of location.
            </div>
          </div>
          <br/>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <div className={shared.TextArea}>
                {formDataFieldsFifthSection?.AditionalOwnedPropertiesCounties}
              </div>
            </div>
          </div>

          <div className={shared.Step}>SECTION 6: Affirmation and Signature</div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <b>
                I understand if I make a false statement on this form, I could
                be found guilty of a Class A misdemeanor or a state jail felony
                under Penal Code Section 37.10.
              </b>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <div className={shared.PrintedName}>
                I, {" "}
                <div className={shared.Width50}>
                  {" "}
                  <div className={shared.Value}>
                    {formDataFieldsSixthSection?.PrintedName}
                  </div>
                  <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                    Property Owner, Authorized Representative Name
                  </div>
                </div>
                , &nbsp;
                <div className={shared.Width30}>
                  <div className={shared.Value}>
                    {formDataFieldsSixthSection?.Title}
                  </div>
                  <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                    Title/Authorization
                  </div>
                </div>
                , swear or affirm the following:
              </div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={`${shared.Column12} ${shared.MarginLeft20}`}>
              <ol className={shared.OrderedList}>
                  <li>that each fact contained in this application is true and correct;</li>
                  <li>that the property described in this application meets the qualifications under
                    Texas law for the special appraisal claimed;</li>
                  <li>that I have read and understand the Notice Regarding Penalties for Making or
                    Filing an Application Containing a False Statement;</li>
              </ol>
            </div>
          </div>
          <br />
          <div className={shared.Row}>
            <div className={shared.Column6}>
              <div className={shared.Value}>
                {formDataFieldsSixthSection?.Signature}
              </div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                Signature of Property Owner or Authorized Representative
              </div>
            </div>
            <div className={shared.Column6}>
              <div className={shared.Value}>
                {formDataFieldsSixthSection?.SignatureDate
                  ? new Date(formDataFieldsSixthSection?.SignatureDate).toDateString() : ""}
              </div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>Date</div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column6}>
              <div className={shared.Value}>
                {formDataFieldsSixthSection?.AdditionalOwnerSignature}
              </div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>
                Signature of Additional Property Owner/Applicant (if any)
              </div>
            </div>
            <div className={shared.Column6}>
              <div className={shared.Value}>
                {formDataFieldsSixthSection?.AdditionalOwnerDate
                  ? new Date(formDataFieldsSixthSection?.AdditionalOwnerDate).toDateString() : ""}
              </div>
              <div className={`${shared.ValueLabelBelow} ${classes.ValueLabelBelow}`}>Date</div>
            </div>
          </div>
          <br />
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <div className={`${shared.NumericTitle}`}>*</div>
              May be used by appraisal district to determine eligibility for
              persons age 65 or older exemption or surviving spouse exemptions
              (Tax Code §11.43(m))
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <div className={`${shared.NumericTitle}`}>**</div>
              Social security number disclosure may be required for tax
              administration and identification. (42 U.S.C. §405(c)(2)(C)(i);
              Tax Code §11.43(f)). A driver’s license number, personal
              identification number or social security number disclosed in an
              exemption application is confidential and not open to public
              inspection, except as authorized by Tax Code §11.48(b).
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <div className={`${shared.NumericTitle}`}>***</div>
              May be confidential under Government Code §552.137; however, by
              including the email address on this form, you are affirmatively
              consenting to its release under the Public Information Act.
            </div>
          </div>
          {GetFooter(2)}
        </div>
        <div className={shared.PageBreak}></div>

        <div className={shared.PrintTogether}>
          {GetHeader("Residence Homestead Exemption Application", "Form 50-114")}
          <div className={shared.Row}>
            <table className={`${shared.Column12} ${classes.ImportantInformationTable}`}>
              <tr>
                <th colSpan={2}>
                  <div className={`${shared.FormTitle} ${shared.TextCenter}`}>Important Information</div>
                </th>
              </tr>
              <tr>
                <td className={shared.Width50}>
                  <p>
                    <b>GENERAL INSTRUCTIONS</b>
                    <br/>
                    This application is for claiming residence homestead exemptions
                    pursuant to Tax Code Sections 11.13, 11.131, 11.132, 11.133, 11.134
                    and 11.432. Certain exemptions may also require Form 50-114-A. The
                    exemptions apply only to property that you own and occupy as your
                    principal place of residence.
                  </p>
                  <p>
                    <b>FILING INSTRUCTIONS</b>
                    <br/>
                    File this form and all supporting documentation with the appraisal
                    district office in each county in which the property is located
                    generally between Jan. 1 and April 30 of the year for which the
                    exemption is requested. <b>Do <u>not</u> file this document with the Texas
                    Comptroller of Public Accounts.</b> A directory with contact information
                    for appraisal district offices is on the Comptroller’s website.
                  </p>
                  <p>
                    <b>APPLICATION DEADLINES</b>
                    <br/>
                    Generally, the completed application and required documentation is
                    due no later than April 30 of the year for which the exemption is requested.
                    <br/>
                    A late application for a residence homestead exemption, including age 65 or
                    older or disabled, may be filed up to two years after the deadline for
                    filing has passed. (Tax Code Section 11.431)
                    <br />
                    A late application for a residence homestead exemption filed for a disabled
                    veteran (not a surviving spouse) under Tax Code Sections 11.131 or 11.132
                    may be filed up to 5 years after the delinquency date. A late application
                    for a residence homestead exemption for a surviving spouse of a disabled
                    veteran under Tax Code Sections 11.131 or 11.132 may be filed up to two years
                    after the delinquency date. (Tax Code Section 11.439)
                    <br/>
                    If the chief appraiser grants the exemption(s), property owner does not need
                    to reapply annually, but must reapply if the chief appraiser requires it,
                    unless seeking to apply the exemption to property not listed in this application.
                    <br/>
                    Property owners already receiving a general residence homestead exemption who
                    turn age 65 in that next year are not required to apply for age 65 or older exemption
                    if accurate birthdate information is included in the appraisal district records or in the
                    information the Texas Department of Public Safety provided to the appraisal district
                    under Transportation Code Section 521.049. (Tax Code Section 11.43(m))
                  </p>
                  <p>
                    <b>REQUIRED DOCUMENTATION</b>
                    <br/>
                    Attach a copy of property owner’s driver’s license or state-issued personal
                    identification certificate. The address listed on the driver’s license or state-issued
                    personal identification certificate must correspond to the property address for
                    which the exemption is requested. Property owners who reside in certain facilities
                    or participate in a certain address confidentiality program may be exempt from this
                    requirement. The chief appraiser may waive the requirements for certain active duty
                    U.S. armed services members or their spouses or holders of certain driver’s licenses.
                    <br/>
                    <b>Heir property</b> is property owned by one or more individuals, where at least
                    one owner claims the property as a residence homestead, and the property was
                    acquired by will, transfer on death deed, or intestacy. An heir property owner
                    not specifically identified as the residence homestead owner on a deed or other
                    recorded instrument in the county where the property is located must provide:
                    <ul className={shared.UnorderedList}>
                      <li>an affidavit establishing ownership of interest in the property (See Form 114-A);</li>
                      <li>a copy of the prior property owner’s death certificate;</li>
                      <li>a copy of the property’s most recent utility bill; and</li>
                      <li>A citation of any court record relating to the applicant’s ownership of the
                      property, if available.</li>
                    </ul>
                    Each heir property owner who occupies the property as a principal residence, other
                    than the applicant, must provide an affidavit that authorizes the submission of this
                    application (See Form 50-114-A).
                    <br/>
                    <b>Manufactured homeowners</b> must provide:
                    <ul className={shared.UnorderedList}>
                      <li>a copy of the Texas Department of Housing and Community Affairs statement
                      of ownership showing that the applicant is the owner of the manufactured
                      home;</li>
                      <li>a copy of the sales purchase agreement, other applicable contract or
                      agreement or payment receipt showing that the applicant is the purchaser of
                      the manufactured home; or</li>
                      <li>a sworn affidavit (see Form 50-114-A) by the applicant indicating that:
                        <ol className={shared.OrderedList}>
                          <li>the applicant is the owner of the manufactured home;</li>
                          <li>the seller of the manufactured home did not provide the applicant with
                          the applicable contract or agreement; <b><u>and</u></b></li>
                          <li>the applicant could not locate the seller after making a good faith effort.</li>
                        </ol>
                      </li>
                    </ul>
                  </p>
                  <p>
                    <b>ADDITIONAL INFORMATION REQUEST</b>
                    <br/>
                    The chief appraiser may request additional information to evaluate this application.
                    Property owner must comply within 30 days of the request or the application will
                    be denied. The chief appraiser may extend this deadline for a single period not to
                    exceed 15 days for good cause shown. (Tax Code Section 11.45)
                  </p>
                </td>
                <td className={`${shared.Width50} ${classes.RightPart}`}>
                  <p>
                    <b>DUTY TO NOTIFY</b>
                    <br/>
                    Property owner must notify the chief appraiser in writing
                    before May 1 of the year after his or her right to this
                    exemption ends.
                  </p>
                  <p>
                    <b>EXEMPTION QUALIFICATIONS</b>
                    <br/>
                    <b>General Residence Homestead Exemption</b> (Tax Code Section 11.13(a) and (b)):
                    <br/>
                    A property owner who acquires property after Jan. 1 may receive the residence
                    homestead exemption for the applicable portion of that tax year immediately on
                    qualification of the exemption, if the previous owner did not receive the same
                    exemption for the tax year. The property owner must occupy the property as the
                    owner’s primary residence and the residence homestead exemption cannot be
                    claimed by the property owner on any other property.
                    <br/>
                    <b>Disabled Person Exemption</b> (Tax Code Section 11.13(c) and (d)):
                    <br/>
                    Persons under a disability for purposes of payment of disability insurance benefits
                    under Federal Old-Age, Survivors, and Disability Insurance. Property owners not
                    identified on a deed or other instrument recorded in the applicable real property
                    records as an owner of the residence homestead must provide an affidavit or
                    other compelling evidence establishing the applicant’s ownership interest in the
                    homestead. (See Form 50-114-A) An eligible disabled person age 65 or older may
                    receive both exemptions in the same year, but not from the same taxing units.
                    Contact the appraisal district for more information.
                    <br/>
                    <b>Age 65 or Older Exemption</b> (Tax Code Section 11.13(c) and (d)):
                    <br/>
                    This exemption is effective Jan. 1 of the tax year in which the property owner
                    becomes age 65. Property owners not identified on a deed or other instrument
                    recorded in the applicable real property records as an owner of the residence
                    homestead must provide an affidavit or other compelling evidence establishing the
                    applicant’s ownership interest in the homestead. (See Form 50-114-A) An eligible
                    disabled person age 65 or older may receive both exemptions in the same year, but
                    not from the same taxing units. Contact the appraisal district for more information.
                    <br/>
                    <b>Surviving Spouse of an Individual Who Qualified for
                      Age 65 or Older Exemption</b> (Tax Code Section 11.13(q)):
                    <br/>
                    Surviving spouse of person who qualified for the age 65 or older exemption may
                    receive this exemption if the surviving spouse was 55 years of age or older when
                    the qualifying spouse died. The property must have been the surviving spouse’s
                    residence homestead at the time of death and remain the surviving spouse’s
                    residence homestead. This exemption cannot be combined with an exemption
                    under 11.13(d).
                    <br/>
                    <b>100 Percent Disabled Veterans Exemption</b> (Tax Code Section 11.131(b)):
                    <br/>
                    Property owner who has been awarded a 100 percent disability compensation due
                    to a service-connected disability and a rating of 100 percent disabled or individual
                    unemployability from the U.S. Department of Veterans Affairs or its successor.
                    Documentation must be provided to support this exemption request.
                    <br/>
                    <b>Surviving Spouse of a Disabled Veteran Who Qualified or Would Have Qualified
                    for the 100 Percent Disabled Veteran’s Exemption</b> (Tax Code Section 11.131(c) and (d)):
                    <br/>
                    Surviving spouse of a disabled veteran (who qualified for an exemption under Tax
                    Code Section 11.131(b) at the time of his or her death or would have qualified for
                    the exemption if the exemption had been in effect on the date the disabled veteran
                    died) who has not remarried since the death of the veteran. The property must have
                    been the surviving spouse’s residence homestead at the time of the veteran’s death
                    and remain the surviving spouse’s residence homestead.
                    <br/>
                    <b>Donated Residence Homestead of Partially Disabled Veterann</b> (Tax Code Section 11.132(b)):
                    <br/>
                    A disabled veteran with a disability rating of less than 100 percent with a residence
                    homestead donated by a charitable organization at no cost or at some cost that
                    is not more than 50 percent of the good faith estimate of the market value of the
                    residence homestead as of the date the donation is made. Documentation must be
                    provided to support this exemption request.
                    <br/>
                    <b>Surviving Spouse of a Disabled Veteran Who Qualified for the Donated
                    Residence Homestead Exemption</b> (Tax Code Section 11.132(c) and (d)):
                    <br/>
                    Surviving spouse of a disabled veteran (who qualified for an exemption under Tax
                    Code Section 11.132(b) at the time of his or her death) who has not remarried since
                    the death of the disabled veteran and maintains the property as his or her residence
                    homestead.
                    <br/>
                    <b>Surviving Spouse of a Member of Armed Services Killed in Line of Duty</b>
                    (Tax Code Section 11.133(b) and (c)):
                    <br/>
                    Surviving spouse of a U.S. armed services member who is killed or fatally injured
                    in the line of duty who has not remarried since the death of the service member.
                    Documentation must be provided to support this exemption request.
                    <br/>
                    <b>Surviving Spouse of a First Responder Killed in the Line of Duty</b>
                    (Tax Code Section 11.134):
                    <br/>
                    Surviving spouse of a first responder who is killed or fatally injured in the line of
                    duty who has not remarried since the death of the first responder. Documentation
                    must be provided to support this exemption request.
                  </p>
                </td>
              </tr>
            </table>
          </div>
          {GetFooter(3)}
        </div>
        <div className={shared.PageBreak}></div>
      </div>
    </>
  );
}
